// CSS
// import './Css/vertical-layout-light/style.scss';
// import './Css/style.scss';
// import "@mdi/font/scss/materialdesignicons.scss";
// import "simple-line-icons/scss/simple-line-icons.scss";

// CSS
import 'bootstrap/scss/bootstrap.scss';
import 'animate.css/animate.css';

//Fontawesome PRO
import './Css/fontawesome/scss/fontawesome.scss';
import './Css/fontawesome/scss/light.scss';
// import './Css/fontawesome/scss/regular.scss';
// import './Css/fontawesome/scss/solid.scss';

import './Css/style.scss';

//JS
import 'bootstrap';

// import './Js/template';

import helpers from "./helpers";
window.helpers = helpers;

//NETTE
import naja from 'naja';
import netteForms from 'nette-forms';

//Element-plus
import ElementPlus from 'element-plus';
import elementCS from 'element-plus/es/locale/lang/cs'
import 'element-plus/dist/index.css';

import { createApp } from 'vue/dist/vue.esm-bundler';

import DriverDetail from "./Components/Registrations/Driver/DriverDetail.vue";

export { ElementPlus, elementCS, naja, netteForms, createApp, DriverDetail }
