<script>
import {reactive, toRefs} from 'vue';
import router from "../../router";

export default {
  name: 'DriversClubForm',
  props: {
    driverGuid: {
      type: String,
      require: true
    }
  },
  emits: ['saved'],
  setup(props, {emit}) {
    const data = reactive({
      form: {
        count: 1
      },
    });

    function submit() {
      let formData = { ...data.form, guid: props.driverGuid, action: 'clubRegistration' };
      window.helpers.server.fetchPost(router.registration.xhr.driver, formData).then(res => {
        emit('saved', res);
      });
    }

    return {
      ...toRefs(data),
      submit
    }
  }
}
</script>

<template>
  <div class="drivers-club-form registration">
    <h2>Objednat další vstup do Drivers Clubu</h2>
    <hr>
    <el-form ref="elForm" :model="form" label-width="260px" @submit.native.prevent="submit">
      <el-form-item label="Kolik vstupů objednáváte?" prop="count">
        <el-input-number :min="1" :max="9" v-model="form.count"/>
      </el-form-item>
      <el-form-item label="Celková cena">
        <span>{{ 1500 * form.count }} Kč</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="large" native-type="submit">Objednat a zobrazit pokyny pro platbu</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style>
.drivers-club-form {
  .el-form-item__label, .el-button, span {
    font-size: 1.25rem;
  }

  margin-bottom: 35px;
}

.drivers-club-form.registration input[type=number] {
  height: inherit;
  font-size: 1.25rem;
}
</style>