<script>
import {computed, reactive, toRefs} from 'vue';
import router from "../../../router";

export default {
  name: 'DriverStateChanger',
  props: {
    driver: {
      type: Object,
      required: true
    },
    loggedUser: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const data = reactive({
      elForm: null,
      dialogVisible: false,
      reasonRequired: true,
      newStateString: null,
      showDiscount: false,
      newPrice: computed(_ => {
        let discount = parseInt(data.form.discount);
        if (isNaN(discount)) {
          discount = 0;
        }

        let res = props.driver.category.price - (props.driver.category.price * (discount / 100));
        return toCurrency(res);
      }),
      form: {
        newState: null,
        reason: null,
        discount: null
      }
    });

    function toCurrency(value) {
      return value.toLocaleString('cs-CZ', {style: 'currency', currency: 'CZK', maximumFractionDigits: 0});
    }

    async function submit() {
      await data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.registration.xhr.driver, {
                action: 'changeState',
                guid: props.driver.guid,
                newState: data.form.newState,
                reason: data.form.reason,
                discount: data.form.discount
              })
              .then(_ => {
                data.dialogVisible = false;
              });
        }
      });
    }

    function userHasRole(role) {
      return props.loggedUser.roles.includes(role);
    }

    function open(state, stateString, reasonRequired = true, showDiscount = false) {
      data.form.reason = null;
      data.form.newState = state;
      data.form.discount = showDiscount ? 0 : null;
      data.newStateString = stateString;
      data.reasonRequired = reasonRequired;
      data.dialogVisible = true;
      data.showDiscount = showDiscount && userHasRole('admin');
    }

    return {
      ...toRefs(data),
      submit, open, toCurrency
    }
  }
}
</script>

<template>
  <div v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" class="d-flex flex-column">
    <span>Změnit stav na:</span>
    <el-button-group>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" type="success" @click="open('approved', 'Schváleno', false, true)">Schváleno</el-button>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'not_approved'" type="warning" @click="open('pre_approved', 'Předběžně schváleno')">Předběžně schváleno</el-button>
      <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved'" type="danger" @click="open('not_approved', 'Neschváleno')">Neschváleno</el-button>

      <el-popconfirm title="Opravdu si přejete zrušit tuto přihlášku?" width="300" confirm-button-text="Ano" cancel-button-text="Ne" @confirm="open('cancelled', 'Zrušeno')">
        <template #reference>
          <el-button v-if="driver.state === 'waiting_approve' || driver.state === 'pre_approved' || driver.state === 'not_approved'" type="danger">Zrušeno</el-button>
        </template>
      </el-popconfirm>

    </el-button-group>
  </div>

  <el-dialog
      v-model="dialogVisible"
      title="Změna stavu"
      width="500">
    <el-form ref="elForm" :model="form" label-width="100">
      <el-form-item label="Nový stav" prop="newState">
        <el-input disabled v-model="newStateString"></el-input>
      </el-form-item>

      <el-form-item label="Důvod" prop="reason" :rules="[ { required: reasonRequired, message: 'Vyplňte prosím důvod' } ]">
        <el-input type="textarea" :rows="5" v-model="form.reason"></el-input>
      </el-form-item>

      <el-form-item v-if="reasonRequired === false">
        <el-alert title="Vyplnění důvodu není povinné" type="warning" show-icon :closable="false"/>
      </el-form-item>

      <el-form-item v-if="showDiscount" prop="discount" label="Sleva v %">
        <div class="d-flex flex-column">
          <el-input-number min="0" max="100" v-model="form.discount"></el-input-number>
          <div>Původní cena: {{ toCurrency(driver.category.price) }}</div>
          <div>Nová cena: {{ newPrice }}</div>
        </div>
      </el-form-item>

      <el-form-item v-if="driver.startNumber === null">
        <el-alert title="Info: není vyplněno startovní číslo" type="info" show-icon :closable="false"/>
      </el-form-item>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Storno</el-button>
        <el-button type="primary" @click="submit">
          Změnit
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>