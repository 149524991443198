<script>
import 'simplelightbox/dist/simple-lightbox.css';
import SimpleLightbox from "simplelightbox";

import {onMounted} from 'vue';

export default {
  name: 'SimpleMap',
  setup() {

    onMounted(_ => {
      new SimpleLightbox('.gallery a');
    });

    return {
    }
  }
}
</script>

<template>
  <div class="gallery mb-3">
    <a :href="require('./map.webp')" class="gallery">
      <img src="./map-small.webp" alt="map">
      </a>
  </div>
</template>

<style scoped>

</style>