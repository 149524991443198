const router = {
	gate: {
		xhr: {
			default: '/gate/xhr'
		}
	},
	front: {
		file(filename, ext) {
			return '/soubor/' + filename + '/' + ext;
		},
		xhr: {
			order: '/front/xhr/order'
		},
		ticket: {
			pdfTicket: function (orderId, download) {
				return '/vstupenka/pdf/' + orderId + (download ? '?download=1' : '');
			}
		}
	},
	registration: {
		xhr: {
			driverPhoto: function (guid, type) {
				return '/registration/xhr/driver-photo/' + guid + '?type=' + type;
			},
			driverPhotoRemove: function (guid, id) {
				return '/registration/xhr/driver-photo-remove/' + guid + '?row=' + id;
			},
			driverForm: function (guid) {
				return '/registration/xhr/driver-form/' + guid;
			},
			driver: '/registration/xhr/driver'
		}
	},
	admin: {
		xhr: {
			order: '/admin/xhr/order',
			mail: '/admin/xhr/mail',
			driver: '/admin/xhr/driver',
			group: '/admin/xhr/group',
		}
	}
};

export default router;