<script>
import {computed, onMounted, reactive, toRefs} from 'vue';
import router from "../../../router";
import DriverPhotos from "./DriverPhotos.vue";
import ClickToEdit from "../../ClickToEdit.vue";
import StateTimeline from "./StateTimeline.vue";
import CustomMailSender from "../../Mail/CustomMailSender.vue";
import DriverStateChanger from "./DriverStateChanger.vue";
import DriverMoreInfo from "./DriverMoreInfo.vue";

export default {
  name: 'DriverDetail',
  components: {DriverMoreInfo, DriverStateChanger, CustomMailSender, StateTimeline, ClickToEdit, DriverPhotos},
  props: {
    guid: {
      type: String,
      required: true,
    },
    disableEdit: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    loggedUser: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const data = reactive({
      driver: null,
      photos: null,
      campLocationItems: computed(_ => {
        let m = new Map();
        m.set('in', 'Budu kempovat v areálu. Dodávka, vůz + přívěs, obytný automobil.');
        m.set('out', 'Budu spát mimo areál. Požaduji vjezd na vůz');
        return m;
      }),
      arrivalItems: computed(_ => {
        let m = new Map();
        m.set('first', 'Čtvrtek 16. 5. 2024');
        m.set('second', 'Pátek 17. 5. 2024');
        return m;
      }),
      categoryGroups: null,
      routerRef: computed(_ => {
        return router;
      })
    });

    function load() {
      let postData = {
        action: 'detail',
        guid: props.guid,
      };

      window.helpers.server.fetchPost(router.registration.xhr.driver, postData)
          .then((driver) => {
            data.driver = driver;

            let m = new Map();
            data.driver.category.groups.forEach((group) => {
              m.set(group.id, group.label);
            });
            data.categoryGroups = m;
          });

      let postDataPhotos = {
        action: 'photos',
        guid: props.guid,
      };

      window.helpers.server.fetchPost(router.registration.xhr.driver, postDataPhotos)
          .then((photos) => {
            data.photos = photos;
          });
    }

    onMounted(() => {
      load();
    });



    async function inlineSave(field, value) {
      let postData = {
        action: 'inlineSave',
        guid: props.guid,
        field: field,
        value: value
      };

      let res = await window.helpers.server.fetchPost(router.registration.xhr.driver, postData);
      return res === 'ok';
    }

    return {
      ...toRefs(data),
      inlineSave
    }
  }
}
</script>

<template>
  <div class="driver-detail">
    <state-timeline v-if="driver !== null && isAdmin === false" :actual-state=driver.state></state-timeline>
    <el-card v-if="driver !== null && isAdmin === false && driver.state === 'waiting_user_finish'" class="mb-4">
      <driver-more-info :driver="driver"></driver-more-info>
    </el-card>

    <el-descriptions v-if="driver !== null" border direction="horizontal" :column="1" class="mb-3">
      <el-descriptions-item label="Stav přihlášky">
        <div class="d-flex flex-column gap-3">
          <span>{{ driver.stateString }}</span>
          <div v-if="driver.state === 'finished'" class="d-flex flex-column gap-2">
            <a :href="routerRef.front.file('prohlaseni-spolujizda-2024', 'pdf')"><el-link type="primary"><em class="fal fa-file-pdf fa-2x me-2"></em> Stáhnout PROHLÁŠENÍ O ZPROŠTĚNÍ ZODPOVĚDNOSTI POŘADATELE</el-link></a>
            <el-alert title="Tento dokument je nutné vytisknout, vyplnit a předat při prezentaci" :closable="false" type="warning" show-icon />
          </div>
          <driver-state-changer v-if="isAdmin" :driver="driver" :logged-user="loggedUser"></driver-state-changer>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="Akce">{{ driver.action.title }}</el-descriptions-item>
      <el-descriptions-item v-if="driver.discount" label="Sleva">{{ driver.discount }}%</el-descriptions-item>
      <el-descriptions-item label="Kategorie">{{ driver.category.label }}</el-descriptions-item>
      <el-descriptions-item label="Třída">
        <click-to-edit v-model="driver.group.id" :save-callable="inlineSave" field="group" type="radiobuttons" :disable-edit="disableEdit"
                       :items="categoryGroups"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Celé jméno">
        <click-to-edit v-model="driver.fullName" :save-callable="inlineSave" field="fullName" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Datum narození">
        <click-to-edit v-model="driver.birthDate" :save-callable="inlineSave" field="birthDate" type="datePicker" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Ulice">
        <click-to-edit v-model="driver.street" :save-callable="inlineSave" field="street" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Město">
        <click-to-edit v-model="driver.city" :save-callable="inlineSave" field="city" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="PSČ">
        <click-to-edit v-model="driver.zip" :save-callable="inlineSave" field="zip" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="E-mail">
        <div class="d-flex flex-column">
          <click-to-edit v-model="driver.email" :save-callable="inlineSave" field="email" :disable-edit="disableEdit"></click-to-edit>
          <custom-mail-sender v-if="isAdmin && loggedUser !== null"
                              :ext-id="driver.id"
                              type="driver"
                              :to="driver.email"
                              :reply="loggedUser.data.email"
                              :subject="'Přihláška ' + driver.fullName + ' | ' + driver.stateString">
            <template v-slot:activator="{ onClick }">
              <el-button-group>
                <el-button @click="onClick" type="primary">Odeslat e-mail</el-button>
              </el-button-group>
            </template>
          </custom-mail-sender>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="Telefon">
        <click-to-edit v-model="driver.phone" :save-callable="inlineSave" field="phone" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Číslo řidičského průkazu">
        <click-to-edit v-model="driver.driverId" :save-callable="inlineSave" field="driverId" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Značka">
        <click-to-edit v-model="driver.carBrand" :save-callable="inlineSave" field="carBrand" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Model">
        <click-to-edit v-model="driver.carModel" :save-callable="inlineSave" field="carModel" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Rok výroby">
        <click-to-edit v-model="driver.carYear" :save-callable="inlineSave" field="carYear" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Objem motoru (ccm)">
        <click-to-edit v-model="driver.carEnginePower" :save-callable="inlineSave" field="carEnginePower" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Renovováno v roce">
        <click-to-edit v-model="driver.carRenovationYear" :save-callable="inlineSave" field="carRenovationYear" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Startovní číslo">
        <click-to-edit v-model="driver.startNumber" :save-callable="inlineSave" field="startNumber" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item label="Zajímavosti o vozidle">
        <click-to-edit v-model="driver.carInterest" :save-callable="inlineSave" type="textarea" field="carInterest" :disable-edit="disableEdit" show-delete-button></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" label="Kdy přijedete">
        <click-to-edit v-model="driver.arrival" :save-callable="inlineSave" type="radiobuttons" field="arrival" :disable-edit="disableEdit"
                       :items="arrivalItems"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" label="Vůz s vlekem">
        <click-to-edit v-model="driver.carTrailerCount" :save-callable="inlineSave" type="number" field="carTrailerCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" label="Dodávka">
        <click-to-edit v-model="driver.carVanCount" :save-callable="inlineSave" type="number" field="carVanCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" label="Obytný vůz">
        <click-to-edit v-model="driver.caravanCount" :save-callable="inlineSave" type="number" field="caravanCount" :disable-edit="disableEdit"></click-to-edit>
      </el-descriptions-item>
      <el-descriptions-item v-if="driver.arrival !== null" label="Umśtění">
        <click-to-edit v-model="driver.campLocation" :save-callable="inlineSave" type="radiobuttons" field="campLocation" :disable-edit="disableEdit"
                       :items="campLocationItems"></click-to-edit>
      </el-descriptions-item>
    </el-descriptions>

    <div v-if="photos !== null" class="d-flex gap-2">
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Fotografie - levá strana</span>
          </div>
        </template>
        <driver-photos v-if="photos.left.length > 0  || isAdmin" :editable="isAdmin" :form-hash="guid" type="left" :photos="photos.left"></driver-photos>
      </el-card>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Fotografie - pravá strana</span>
          </div>
        </template>
        <driver-photos v-if="photos.right.length > 0  || isAdmin" :editable="isAdmin" :form-hash="guid" type="right" :photos="photos.right"></driver-photos>
      </el-card>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Fotografie - doplňková</span>
          </div>
        </template>
        <driver-photos v-if="photos.custom1.length > 0  || isAdmin" :editable="isAdmin" :form-hash="guid" type="custom1" :photos="photos.custom1"></driver-photos>
      </el-card>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span>Fotografie - další doplňková</span>
          </div>
        </template>
        <driver-photos v-if="photos.custom2.length > 0  || isAdmin" :editable="isAdmin" :form-hash="guid" type="custom2" :photos="photos.custom2"></driver-photos>
      </el-card>


    </div>
  </div>
</template>

<style>
.driver-detail {
  .el-descriptions__label, .el-descriptions__content, .el-descriptions__cell, .el-button {
    font-size: 1rem !important;
  }

  .el-descriptions__label {
    font-weight: normal !important;
  }

  margin-bottom: 5rem;
}
</style>

<style scoped>

</style>