<script>
import {onMounted, reactive, toRefs, computed} from 'vue';
import {v4 as uuidv4} from 'uuid';
import router from "../../router";
import {ElMessage, ElMessageBox} from 'element-plus';

const formHashKey = 'hash';
const name = 'DriverRegistrationForm';

export default {
  name: name,
  computed: {
    router() {
      return router
    }
  },
  props: {
    actionGuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const data = reactive({
      formDisabled: false,
      uploadSettings: {
        accept: "image/png, image/gif, image/jpeg",
        limit: 3,
        listType: 'picture-card',
        autoUpload: true
      },
      photoDialogVisible: false,
      dialogImageUrl: '',
      formHash: null,
      elForm: null,
      categories: [],
      form: {
        group: null,
        category: null,
        fullName: '',
        birthDate: '',
        street: '',
        city: '',
        zip: '',
        email: '',
        phone: '',
        driverId: '',
        carBrand: '',
        carModel: '',
        carYear: '',
        carEnginePower: '',
        carRenovationYear: '',
        customStartNumber: '',
        carInterest: '',
        photoLeft: [],
        photoRight: [],
        photoCustom1: [],
        photoCustom2: [],
        personalDataAgreement: false,
        trackRulesAgreement: false,
      },
      formFill: {
        group: null,
        category: null,
        fullName: 'Milan Sivák',
        birthDate: '1987-01-27',
        street: 'Střelnice 2287',
        city: 'Česká Lípa',
        zip: '470 01',
        email: 'ms@d5t5.com',
        phone: '737330704',
        driverId: 'EK 123456',
        carBrand: 'Škoda',
        carModel: '130',
        carYear: '1989',
        carEnginePower: '1396',
        carRenovationYear: '2000',
        customStartNumber: '',
        carInterest: '',
        photoLeft: [],
        photoRight: [],
        photoCustom1: [],
        photoCustom2: [],
        personalDataAgreement: false,
        trackRulesAgreement: false,
      },
      groups: computed(_ => {
        if (data.form.category === null) {
          return [];
        }
        return data.categories.find(category => category.id === data.form.category).groups;
      })
    });

    function load() {
      let postData = {action: 'categories', actionGuid: props.actionGuid};
      window.helpers.server.fetchPost(router.registration.xhr.driver, postData)
          .then(categories => {
            data.categories = categories;
          })
    }

    onMounted(_ => {
      data.formHash = uuidv4();
      load();
    });

    async function submit() {
      await data.elForm.validate((valid) => {
        if (valid) {
          //kopie data.form bez photo*
          let formData = {...data.form};
          delete formData.photoLeft;
          delete formData.photoRight;
          delete formData.photoCustom1;
          delete formData.photoCustom2;
          formData.actionGuid = props.actionGuid;

          window.helpers.server.fetchPost(router.registration.xhr.driverForm(data.formHash), formData);
        } else {
          ElMessage.error('Zkontrolujte prosím všechny označené položky formuláře');
        }
      });
    }

    function handlePhotoPreview(uploadFile) {
      data.dialogImageUrl = uploadFile.url;
      data.photoDialogVisible = true;
    }

    function handlePictureRemove(file) {
      window.helpers.server.fetchPost(router.registration.xhr.driverPhotoRemove(data.formHash, file.response.id), {})
    }

    function handleBeforeUploadPhoto() {
      data.formDisabled = true;
    }

    function handleSuccessUploadPhoto() {
      data.formDisabled = false;
    }

    function handleBeforeRemove() {
      return ElMessageBox.confirm('Opravdu odstranit tuto fotografii?').then(
          () => true,
          () => false
      );
    }

    return {
      ...toRefs(data),
      submit,
      handlePhotoPreview,
      handlePictureRemove,
      handleBeforeUploadPhoto,
      handleSuccessUploadPhoto,
      handleBeforeRemove
    }
  }
}
</script>

<template>
  <div class="driver-registration-form">
    <el-dialog v-model="photoDialogVisible" width="95%">
      <div class="d-flex justify-content-center">
        <img class="img-fluid" :src="dialogImageUrl" alt="Náhled obrázku"/>
      </div>
    </el-dialog>

    <el-form :disabled="formDisabled" ref="elForm" :model="form" label-width="250px" @submit.native.prevent="submit">

      <el-form-item label="Kategorie" prop="category" :rules="[
        { required: true, message: 'Vyberte prosím kategorii' }
      ]">
        <el-radio-group v-model="form.category" size="large">
          <el-radio-button v-for="category in categories" :key="category.id" :label="category.id">{{ category.label }}</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="form.category" label="Cena">
        <span class="price">
          {{ categories.find(category => category.id === form.category).price.toLocaleString('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }) }}
        </span>
      </el-form-item>

      <el-form-item v-if="form.category !== null" label="Třída" prop="group" :rules="[
        { required: true, message: 'Vyberte prosím třídu' }
      ]">
        <el-radio-group class="radio-group" v-model="form.group" size="large">
          <el-radio v-for="group in groups" :key="group.id" :label="group.id">{{ group.label }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="Celé jméno" prop="fullName" :rules="[
        { required: true, message: 'Vyplňte prosím celé jméno' }
      ]">
        <el-input v-model="form.fullName" placeholder="Jan Novák"></el-input>
      </el-form-item>

      <el-form-item label="Datum narození" prop="birthDate" :rules="[
        { required: true, message: 'Vyplňte prosím datum narození' }
      ]">
        <el-date-picker
            format="D. M. YYYY"
            v-model="form.birthDate"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="13. 5. 1989"
            :disabledDate="(time) => {
              return time.getTime() > Date.now();
          }"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="Ulice a č.p." prop="street" :rules="[
        { required: true, message: 'Vyplňte prosím ulici a č.p.' }
      ]">
        <el-input v-model="form.street" placeholder="Sosnová 202"></el-input>
      </el-form-item>

      <el-form-item label="Město" prop="city" :rules="[
        { required: true, message: 'Vyplňte prosím město' }
      ]">
        <el-input v-model="form.city" placeholder="Česká Lípa"></el-input>
      </el-form-item>

      <el-form-item label="PSČ" prop="zip" :rules="[
        { required: true, message: 'Vyplňte prosím PSČ' }
      ]">
        <el-input v-model="form.zip" placeholder="470 01"></el-input>
      </el-form-item>

      <el-form-item label="E-mail" prop="email" :rules="[
        { required: true, message: 'Vyplňte prosím e-mail' },
        { type: 'email', message: 'Vyplňte prosím platný e-mail' }
      ]">
        <el-input v-model="form.email" placeholder="jan.novak@gmail.com"></el-input>
      </el-form-item>

      <el-form-item label="Telefon" prop="phone" :rules="[
        { required: true, message: 'Vyplňte prosím telefon' }
      ]">
        <el-input v-model="form.phone" placeholder="+420 777 777 777"></el-input>
      </el-form-item>

      <el-form-item label="Číslo řidičského průkazu" prop="driverId" :rules="[
        { required: true, message: 'Vyplňte prosím číslo řidičského průkazu' }
      ]">
        <el-input v-model="form.driverId" placeholder="EK 780325"></el-input>
      </el-form-item>

      <el-form-item label="Značka" prop="carBrand" :rules="[
        { required: true, message: 'Vyplňte prosím značku' }
      ]">
        <el-input v-model="form.carBrand" placeholder="Škoda, Jawa, Metalex"></el-input>
      </el-form-item>

      <el-form-item label="Model" prop="carModel" :rules="[
        { required: true, message: 'Vyplňte prosím model' }
      ]">
        <el-input v-model="form.carModel" placeholder="130, 350, 160 RS"></el-input>
      </el-form-item>

      <el-form-item label="Rok výroby" prop="carYear" :rules="[
        { required: true, message: 'Vyplňte prosím rok výroby' }
      ]">
        <el-input v-model="form.carYear" placeholder="4 čísla - 1969, 2004"></el-input>
      </el-form-item>

      <el-form-item label="Objem motoru (ccm)" prop="carEnginePower" :rules="[
        { required: true, message: 'Vyplňte prosím objem motoru' }
      ]">
        <el-input v-model="form.carEnginePower" placeholder="1196"></el-input>
      </el-form-item>

      <el-form-item label="Renovováno v roce" prop="carRenovationYear">
        <el-input v-model="form.carRenovationYear" placeholder="4 čísla - 1969, 2004"></el-input>
      </el-form-item>

      <el-form-item label="Vlastní startovní číslo" prop="customStartNumber">
        <el-input v-model="form.customStartNumber" placeholder="Pokud číslo nemáte, nevyplňujte, bude vám přiděleno"></el-input>
      </el-form-item>

      <el-form-item label="Zajímavosti o vozidle" prop="carInterest">
        <el-input v-model="form.carInterest" :rows="5" type="textarea" placeholder="Pro použití při komentování pro diváky"></el-input>
      </el-form-item>

      <el-divider>Fotografie vozu</el-divider>

      <el-form-item label="Levá strana" prop="photoLeft" :rules="[
        { required: true, message: 'Vyberte prosím fotografii vozu' }
      ]">
        <el-upload
            :accept="uploadSettings.accept"
            :limit="uploadSettings.limit"
            v-model:file-list="form.photoLeft"
            :list-type="uploadSettings.listType"
            :auto-upload="uploadSettings.autoUpload"
            :on-preview="handlePhotoPreview"
            :before-remove="handleBeforeRemove"
            :on-remove="handlePictureRemove"
            :before-upload="handleBeforeUploadPhoto"
            :on-success="handleSuccessUploadPhoto"
            :action="router.registration.xhr.driverPhoto(formHash, 'left')"
        >
          <el-icon class="d-flex flex-column gap-1">
            <em class="fal fa-plus"></em>
            <span class="small">Přidat fotografii</span>
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="Pravá strana" prop="photoRight" :rules="[
        { required: true, message: 'Vyberte prosím fotografii vozu' }
      ]">
        <el-upload
            :accept="uploadSettings.accept"
            :limit="uploadSettings.limit"
            v-model:file-list="form.photoRight"
            :list-type="uploadSettings.listType"
            :auto-upload="uploadSettings.autoUpload"
            :on-preview="handlePhotoPreview"
            :before-remove="handleBeforeRemove"
            :on-remove="handlePictureRemove"
            :before-upload="handleBeforeUploadPhoto"
            :on-success="handleSuccessUploadPhoto"
            :action="router.registration.xhr.driverPhoto(formHash, 'right')"
        >
          <el-icon class="d-flex flex-column gap-1">
            <em class="fal fa-plus"></em>
            <span class="small">Přidat fotografii</span>
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="Doplňková fotografie" prop="photoCustom1">
        <el-upload
            :accept="uploadSettings.accept"
            :limit="uploadSettings.limit"
            v-model:file-list="form.photoCustom1"
            :list-type="uploadSettings.listType"
            :auto-upload="uploadSettings.autoUpload"
            :on-preview="handlePhotoPreview"
            :before-remove="handleBeforeRemove"
            :on-remove="handlePictureRemove"
            :before-upload="handleBeforeUploadPhoto"
            :on-success="handleSuccessUploadPhoto"
            :action="router.registration.xhr.driverPhoto(formHash, 'custom1')"
        >
          <el-icon class="d-flex flex-column gap-1">
            <em class="fal fa-plus"></em>
            <span class="small">Přidat fotografii</span>
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="Další doplňková fotografie" prop="photoCustom2">
        <el-upload
            :accept="uploadSettings.accept"
            :limit="uploadSettings.limit"
            v-model:file-list="form.photoCustom2"
            :list-type="uploadSettings.listType"
            :auto-upload="uploadSettings.autoUpload"
            :on-preview="handlePhotoPreview"
            :before-remove="handleBeforeRemove"
            :on-remove="handlePictureRemove"
            :before-upload="handleBeforeUploadPhoto"
            :on-success="handleSuccessUploadPhoto"
            :action="router.registration.xhr.driverPhoto(formHash, 'custom2')"
        >
          <el-icon class="d-flex flex-column gap-1">
            <em class="fal fa-plus"></em>
            <span class="small">Přidat fotografii</span>
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item prop="personalDataAgreement" :rules="[
        { required: true, message: 'Vyplňte prosím souhlas se zpracováním osobních údajů', type: 'enum', enum: [true] }
      ]">
        <el-checkbox v-model="form.personalDataAgreement" label="Souhlasím se zpracováním osobních údajů"></el-checkbox>
      </el-form-item>

      <el-form-item prop="trackRulesAgreement" :rules="[
        { required: true, message: 'Vyplňte prosím souhlas s pravidly okruhu', type: 'enum', enum: [true] }
      ]">
        <el-checkbox v-model="form.trackRulesAgreement" label="Souhlasím s pravidly okruhu"></el-checkbox>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" size="large" native-type="submit">Odeslat přihlášku</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped>
.price {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>

<style>
.driver-registration-form {
  .el-form-item__error {
    font-size: 0.9rem;
  }

  .el-button, .el-alert__description, .el-divider__text, .el-form-item__label, .el-input__inner, .el-checkbox__label, .el-radio__label, .el-radio-button__inner, .el-textarea__inner {
    font-size: 1.3rem !important;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>