<script>
import {reactive, toRefs} from 'vue';
import QRCodeVue3 from "qrcode-vue3";

export default {
  name: 'DriversClubDetail',
  components: {
    QRCodeVue3
  },
  props: {
    driversClub: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const data = reactive({});

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div class="drivers-club-detail">
    <template v-if="driversClub.state === 'waitingforpayment'">
      <h2>Pokyny k platbě</h2>
      <el-descriptions direction="horizontal" :column="1" border>
        <el-descriptions-item label="Částka k úhradě">{{ 1500 * driversClub.count }} Kč</el-descriptions-item>
        <el-descriptions-item label="Číslo účtu">{{ driversClub.payAccount }}/{{ driversClub.payCode }}</el-descriptions-item>
        <el-descriptions-item label="Variabilní symbol">{{ driversClub.varSymbol }}</el-descriptions-item>
        <el-descriptions-item label="IBAN">{{ driversClub.payIban }}</el-descriptions-item>
        <el-descriptions-item label="BIC">{{ driversClub.payBic }}</el-descriptions-item>
        <el-descriptions-item label="Příjemce">{{ driversClub.payReceiver }}</el-descriptions-item>
      </el-descriptions>
      <h2 class="mt-3">Nebo pomocí QR kódu</h2>
      <QRCodeVue3
          :key="driversClub.qrCodeData"
          :value="driversClub.qrCodeData"
          :cornersSquareOptions="{ type: 'square'}"
          :dotsOptions="{ type: 'square' }"
      />
    </template>
    <template v-if="driversClub.state === 'paid'">
      <el-alert title="Platba byla úspěšně zaznamenána" type="success" show-icon :closable="false"/>
    </template>
  </div>
</template>

<style>
.drivers-club-detail {
  .el-descriptions__cell, .el-alert__title {
    font-size: 1.25rem !important;
    line-height: 1.4rem;
  }

  margin-bottom: 35px;
}
</style>