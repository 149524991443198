<script>
import {computed, reactive, toRefs, watch} from 'vue';
import {ElMessageBox} from 'element-plus'
import router from "../../router";

export default {
  name: 'TicketForm',
  props: {
    privacyPolicyUrl: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const data = reactive({
      form: {
        adultCount: 0,
        childCount: 0,
        fullName: '',
        email: '',
        phone: '',
        dayId: 0,
        seatId: 0,
        gdpr: false
      },
      disabledForm: false,
      selectedDay: null,
      selectedSeat: null,
      totalPrice: computed(() => {
        if (!data.selectedSeat) {
          return 0;
        }
        return data.selectedSeat.priceAdult * data.form.adultCount + data.selectedSeat.priceChild * data.form.childCount;
      })
    });

    watch(() => data.selectedDay, () => {
      data.form.dayId = data.selectedDay ? data.selectedDay.dayId : 0;
      data.selectedSeat = null;
      data.form.adultCount = 0;
      data.form.childCount = 0;
    });

    watch(() => data.selectedSeat, () => {
      data.form.seatId = data.selectedSeat ? data.selectedSeat.seatId : 0;
    });

    function computeMaxCount(seat) {
      let ret = seat.totalTickets - seat.usedTickets;
      return ret < 0 ? 0 : ret;
    }

    function messageBox(message) {
      ElMessageBox.alert(message, {
        dangerouslyUseHTMLString: true,
      });
    }

    function submit() {
      let errors = [];
      //test zda je vybrany den
      if (!data.selectedDay) {
        errors.push('Vyberte den');
      }

      //test zda je vybrana sedadla
      if (!data.selectedSeat) {
        errors.push('Vyberte umístění');
      }

      //test zda je vyplneno jmeno
      if (!data.form.fullName) {
        errors.push('Vyplňte celé jméno');
      }

      //test zda je vyplnen email a zda ma opravdu format emailu podle regex
      if (!data.form.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.form.email)) {
        errors.push('Vyplňte platný e-mail');
      }

      //test zda je vyplnen telefon
      if (!data.form.phone) {
        errors.push('Vyplňte telefon');
      }

      //test zda je souhlas s GDPR
      if (!data.form.gdpr) {
        errors.push('Musíte souhlasit se zpracováním osobních údajů');
      }

      //test zda je vyplnen pocet dospelych nebo deti, staci alespon jedno z nich
      if (data.form.adultCount === 0 && data.form.childCount === 0) {
        errors.push('Musíte vyplnit alespoň jedno z polí počet dospělých nebo počet dětí');
      }

      if (data.selectedSeat) {
        let availableTickets = data.selectedSeat.totalTickets - data.selectedSeat.usedTickets;
        //celkovy pocet osob nesmi byt vetsi nez pocet volnych mist
        if (data.form.adultCount + data.form.childCount > availableTickets) {
          errors.push('Celkový počet osob nesmí být větší než počet volných míst: ' + availableTickets);
        }
      }

      //hodnota objednavky musi byt vetsi nez 0
      if (data.totalPrice <= 0) {
        errors.push('Celková cena musí být větší než 0 Kč');
      }

      if (errors.length > 0) {
        messageBox(errors.join('<br>'));
        return;
      }

      let xhrData = {
        action: 'createOrder',
        actionId: props.action.id,
        ...data.form,
      };

      data.disabledForm = true;
      window.helpers.server.fetchPost(router.front.xhr.order, xhrData)
          .then((response) => {
            console.log(response);
          })
          .finally(() => {
            data.disabledForm = false;
          });
    }

    return {
      ...toRefs(data), computeMaxCount, submit
    }
  }
}
</script>

<template>
  <div class="registration">
    <form @submit.prevent="submit">
      <fieldset>
        <h2 class="mb-4">Rezervace</h2>
        <h3>Den</h3>
        <div class="labels">
          <div v-for="day in action.data.days">
            <label :for="'day-' + day.dayId">
              <input type="radio" :id="'day-' + day.dayId" name="day" :value="day" v-model="selectedDay"> {{ day.day }}
            </label>
          </div>
        </div>

        <h3>Umístění</h3>
        <template v-if="selectedDay">
          <div class="labels">
            <div v-for="seat in selectedDay.seats">
              <label :for="'seat-' + seat.seatId">
                <input type="radio" :id="'seat-' + seat.seatId" name="seat" :value="seat" v-model="selectedSeat"> {{ seat.seat }}
              </label>
            </div>
          </div>
        </template>
        <p v-else>❓ Nejprve vyberte den</p>

        <template v-if="selectedSeat">
          <h3>
            <label for="adultCount">Počet dospělých osob</label> <span class="text-black">/ {{ selectedSeat.priceAdult }} Kč</span>
          </h3>
          <div class="labels input-labels">
            <span @click="form.adultCount = 1">1</span>
            <span @click="form.adultCount = 2">2</span>
            <span @click="form.adultCount = 3">3</span>
            <input type="number" v-model="form.adultCount" name="adultCount" min="0" id="adultCount" :max="computeMaxCount(selectedSeat)" required>
          </div>
          <h3>
            <label for="childCount">Počet dětí do 15 let</label> <span class="text-black">/ {{ selectedSeat.priceChild }} Kč</span>
          </h3>
          <div class="labels input-labels">
            <span @click.stop="form.childCount = 1">1</span>
            <span @click.stop="form.childCount = 2">2</span>
            <span @click.stop="form.childCount = 3">3</span>
            <input type="number" v-model="form.childCount" name="childCount" min="0" :max="computeMaxCount(selectedSeat)" id="childCount" required>
          </div>
        </template>
        <template v-else>
          <h3>
            <label for="adultCount">Počet dospělých osob</label>
          </h3>
          <p>❓ Nejprve vyberte umístění</p>
          <h3>
            <label for="adultCount">Počet dětí do 15 let</label>
          </h3>
          <p>❓ Nejprve vyberte umístění</p>
        </template>

        <h2 class="mt-5">Celková cena <strong class="text-black">{{ totalPrice }}</strong> Kč</h2>

        <hr class="mb-5">

        <h3 class="mt-3">
          <label for="fullName">Jméno a příjmení</label>
        </h3>
        <div class="labels">
          <input type="text" name="fullName" placeholder="Jan Novák" id="fullName" required v-model="form.fullName">
        </div>

        <h3 class="mt-3">
          <label for="email">E-mail</label>
        </h3>
        <div class="labels">
          <input type="text" name="email" placeholder="jan.novak@gmail.com" id="email" required v-model="form.email">
        </div>

        <h3 class="mt-3">
          <label for="phone">Telefon</label>
        </h3>
        <div class="labels mb-4">
          <input type="text" name="phone" placeholder="+420 777 777 777" id="phone" required v-model="form.phone">
        </div>

        <label for="gdpr" class="text-black">
          <label for="gdpr">
            <input type="checkbox" name="gdpr" id="gdpr" v-model="form.gdpr" required>
          </label> Souhlasím se zpracováním <a :href="privacyPolicyUrl" target="_blank">osobních údajů</a>
        </label>

        <input type="submit" class="d-block my-5 text-white" value="Pokračovat v rezervaci" :disabled="disabledForm">

      </fieldset>
    </form>
  </div>
</template>

<style>
.el-message-box__message p {
  font-size: 1.25rem;
}
</style>

<style scoped lang="scss">
input[type="radio"] {
  min-width: 28px;
}

.input-labels {
  display: flex;
  gap: 5px;

  span {
    background-color: #007e31;
    font-size: 1.75rem;
    width: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #005e21;
    }
  }
}
</style>