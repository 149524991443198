<script>
import {reactive, toRefs} from 'vue';

export default {
  name: 'StateTimeline',
  props: {
    actualState: {
      type: String,
      required: true
    },
  },
  setup() {
    const data = reactive({
      count: 0,
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>
  <div class="state-timeline">
    <el-timeline>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'init' }">
        Vyplníte formulář pro vytvoření přihlášky
      </el-timeline-item>
      <el-timeline-item
          :class="{ 'fw-bold':actualState === 'waiting_approve' }"
          timestamp="Předběžné přijetí znamená, že máme např. podobných vozů více a chceme upřednostnit dříve přihlášené - a čekáme na jejich potvrzení">
        My přihlášku zkontrolujeme a rozhodneme, zda bude přijata, zamítnuta nebo předběžně přijata
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_for_payment_1' }">
        Pokud bude přihláška přijata, vygenerujeme vám platební údaje a budeme čekat na vaší platbu
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_user_finish' }">
        Po přijetí platby vám zašleme potvrzení o přijetí přihlášky a bude na vás čekat formulář s doplňujícími údaji
      </el-timeline-item>
<!--      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_approver_finish' }">-->
<!--        Ten opět zkontrolujeme a případně vás požádáme o doplnění-->
<!--      </el-timeline-item>-->
      <el-timeline-item :class="{ 'fw-bold':actualState === 'waiting_for_payment_2' }">
        Pokud budou doplňující údaje vyžadovat další platbu, vygenerujeme vám platební údaje a budeme čekat na zaplacení
      </el-timeline-item>
      <el-timeline-item :class="{ 'fw-bold':actualState === 'finished' }">
        Po přijetí platby vám zašleme potvrzení o dokončení přihlášky a budeme se na vás těšit
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<style>
.state-timeline {

  .el-timeline-item__content {
    font-size: 1.3rem !important;
  }


  .el-timeline li {
    margin-bottom: 0;
  }

}
</style>