import {ElementPlus, elementCS,
	createApp, naja,
	netteForms, DriverDetail} from './core.js';

import './Css/styly.scss';

//COMPONENTS
import OrderDetail from "./Components/Order/Detail/OrderDetail.vue";
import SimpleMap from "./Components/Gallery/SimpleMap/SimpleMap.vue";
import DriverRegistrationForm from "./Forms/Registration/DriverRegistrationForm.vue";
import StateTimeline from "./Components/Registrations/Driver/StateTimeline.vue";
import DriversClubWrapper from "./Components/Registrations/DriversClub/DriversClubWrapper.vue";
import TicketForm from "./Forms/Registration/TicketForm.vue";

import {onMounted} from "vue";

export const app = createApp({
	setup() {
		onMounted(() => {
			window.Nette = netteForms;
			naja.initialize.bind(naja);
			netteForms.initOnLoad();
		});

		return {
		}
	}
})
	.component(OrderDetail.name, OrderDetail)
	.component(SimpleMap.name, SimpleMap)
	.component(DriverRegistrationForm.name, DriverRegistrationForm)
	.component(DriverDetail.name, DriverDetail)
	.component(StateTimeline.name, StateTimeline)
	.component(DriversClubWrapper.name, DriversClubWrapper)
	.component(TicketForm.name, TicketForm)
	.use(ElementPlus, { locale: elementCS })
	.mount('#app');